<template>
  <v-card outlined class="pa-4 font-weight-bold">
    <v-card-title class="font-weight-bold">Contribution to Sales</v-card-title>
    <HorizontalBar :chartData="chartData" :options="options"></HorizontalBar>
  </v-card>
</template>

<script>
export default {
  name: "HorizontalBarArea",
  components: {
    HorizontalBar: () => import("@/components/chart/HorizontalBar"),
  },
  computed: {
    chartData() {
      return this.$store.getters.promoOverviewHorizontalChartData;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    options() {
      const gridlines_color = this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)";
      const fontColor = this.$vuetify.theme.themes[this.theme].chartFont;
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: gridlines_color,
                zeroLineColor: gridlines_color,
              },
              ticks: {
                min: 0,
                max: 1,
                fontColor: fontColor,
                callback: function(value) {
                  return `${value * 100}%`;
                },
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: gridlines_color,
                zeroLineColor: gridlines_color,
              },
              ticks: {
                fontColor: fontColor,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return (tooltipItem.xLabel * 100).toFixed(2) + "%";
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped></style>
